import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import { useApi } from "../../../api/useApi";
import Spinner from "../../Loaders/Spinner";
import { allMappedInstances } from "../../../api/ruleQueries";
import ErrorMessages from "../../Notifications/ErrorMessages";
import Button from "../../Button";
import Modal from "../../Modal";
import SortTable from "../../Table/SortTable";
import { ruleStandardById } from "../../../common/paths";
import TableButton from "../../Button/TableButton";
import StyledLink from "../../StyledLink";
import { format } from "date-fns";
import ReactTooltip from "react-tooltip";
import styled from "styled-components/macro";
import { dataSourceFailureInstanceDetails } from "../../../common/paths";
import { MdWarning } from "react-icons/md";
import {
  failureOpportunities,
  totalFailures,
  rowCount,
  constraintFailuresCount,
  qualityScore,
  scoreHistory,
  ruleFailures,
  failuresHistory,
} from "../../../api/dataSourceQueries";
import FailureDetailsModal from "../../TableRowView/FailureDetailsModal";
import FailureViewDetails from "../../../views/DataSourcesPage/Reports/FailureViewDetailsModalDrillIn";
import SpinningLoader from "../../Loaders/SpinningLoader";
import ViewMappingModal from "../../../views/RuleStandardPage/ViewMappingModal";
import {
  setRuleInstanceEnabledFlag,
  setRuleInstanceApprovalState,
} from "../../../api/ruleMutations";
import { AuthContext } from "../../../contexts/AuthContext";
import { approvalStates } from "../../../common/formOptions";
import { StyledSelect } from "../../Form/FormControls";
import { toast } from "react-toastify";
import { canUseBCA } from "../../../common/helpers/auth";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";

const WarningIcon = styled.div`
  color: red;
  position: absolute;
  left: -1.5rem;
`;

const RemoveInstance = ({
  removeInstanceFromState,
  ruleToDelete,
  setRuleToDelete,
  setShowConfirmRemove,
}) => {
  const [
    {
      errors: removeRuleErrors,
      loading: removeRuleDataLoading,
      data: removeRuleData,
    },
    removeRuleApi,
  ] = useApi();

  //handle remove rule update
  useEffect(() => {
    if (removeRuleData && ruleToDelete) {
      removeInstanceFromState(ruleToDelete?.ruleInstanceId);
      setRuleToDelete(null);
      setShowConfirmRemove(false);
    }
  }, [
    removeRuleData,
    setShowConfirmRemove,
    ruleToDelete,
    setRuleToDelete,
    removeInstanceFromState,
  ]);

  //callback for removing a row
  const removeRuleStandard = useCallback(
    (ruleInstanceId) => {
      const variables = {
        ruleInstanceId: ruleInstanceId,
        enableState: "DISABLED",
      };
      removeRuleApi({ query: setRuleInstanceEnabledFlag, variables });
    },
    [removeRuleApi]
  );

  return (
    <>
      <p>
        Are you sure you wish to remove {ruleToDelete?.title ?? "this"} Mapping?
      </p>

      {removeRuleErrors && <ErrorMessages errors={removeRuleErrors} />}
      <div>
        <Button
          type="button"
          list="true"
          disabled={removeRuleDataLoading}
          danger
          onClick={() => removeRuleStandard(ruleToDelete?.ruleInstanceId)}
        >
          {removeRuleDataLoading ? <Spinner /> : "Yes"}
        </Button>
        <Button
          type="button"
          disabled={removeRuleDataLoading}
          onClick={() => {
            setRuleToDelete(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

const FailureDetailsModalContainer = ({ showFailureDetails }) => {
  const query = `
    query($id: Int!, $reportId: UUID!) {
       dataSource(id: $id) {
         id
         name
        columns{
          id
          name
          ordinal
        }
        priorityLevel
        qualityImpact
        description
        latestRefreshSummaryStatus
        ruleInstancesCount
        latestReport{
          refreshSummaryId
        }
         reportByIdWithAlert(reportId: $reportId){
           refreshSummaryId
           timestamp
           isGeneratingCachedReport
           refreshSummary {
            createdOn
            batchName
          }
          columnProfiles{
            profileResults {
              columnId
              value
              valueSubType
              valueType
            }
          }
          failureDetails{
            dataSourceMetrics {
              totalFailuresCount
              totalSuccessfulRowsCount
              totalHighPriorityFailuresCount
              totalOtherPriorityFailuresCount
            }
            columnEntries {
              columnId
              details {
                failureCount
                percentFailureCount
                ruleStandardInstancedName
                standardColumnName
              }
              failureCount
              failureCountChange
              name
              previouslyTopOrder
            }
            ruleStandardEntries {
              details {
                columnName
                failureCount
                percentFailureCount
                standardColumnName
              }
              failureCount
              failureCountChange
              name
              previouslyTopOrder
              rulesStandardId
            }
          }
           ${failureOpportunities.fragment}
           ${totalFailures.fragment}
           ${rowCount.fragment}
           ${qualityScore.fragment}
           ${constraintFailuresCount.fragment}
           ${scoreHistory.fragment}
           ${ruleFailures.fragment}
           ${failuresHistory.fragment}
         }
       }
   }
  `;
  const [{ loading, errors, data: apiData }, fetch] = useApi();

  const sourceId = showFailureDetails?.dataSourceId;
  const refreshSummaryId = showFailureDetails?.refreshSummaryId;
  const failedRuleInstanceId = showFailureDetails?.failedRuleInstanceId;
  const failedRuleInstanceVersionId =
    showFailureDetails?.failedRuleInstanceVersionId
      ? Number(showFailureDetails?.failedRuleInstanceVersionId)
      : null;

  const failedRuleStandardVersionId =
    showFailureDetails?.failedRuleStandardVersionId;

  useEffect(() => {
    if (sourceId && refreshSummaryId) {
      const variables = {
        id: sourceId,
        reportId: refreshSummaryId,
      };

      fetch({ query: query, variables });
    }
  }, [sourceId, refreshSummaryId, fetch, query]);
  if (loading)
    return (
      <div>
        <SpinningLoader text="Loading Failure Details" />
      </div>
    );
  return (
    <>
      {errors && <ErrorMessages errors={errors} />}
      <FailureViewDetails
        sourceId={sourceId}
        refreshSummaryId={refreshSummaryId}
        failedRuleInstanceId={failedRuleInstanceId}
        failedRuleInstanceVersionId={failedRuleInstanceVersionId}
        failedRuleStandardVersionId={failedRuleStandardVersionId}
        apiData={apiData}
      />
    </>
  );
};

const DataSourceRulesListWidget = () => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmRemove, setShowConfirmRemove] = useState(null);
  const { user } = useContext(AuthContext);
  const [savingPdf, setSavingPdf] = useState(false);

  const ref = useRef(null);

  //Init Data Fetch
  const [
    {
      loading: availableStandardsLoading,
      errors: availableStandardsErrors,
      data: standards,
    },
  ] = useApi(allMappedInstances, {
    standardType: "RULE",
    // first 9999 also fixes 3767 due to HC change of defaults
    first: 9999, //added as a hack to enable filtering until we get case-insens filtering
    where: {
      enabled: { eq: true },
      instances: {
        some: {
          dataSource: {
            enabled: { eq: true },
          },
        },
      },
    },
  });

  const removeInstanceFromState = (ruleInstanceId) => {
    setInstances((prev) => {
      const clone = [...prev].filter((c) => c?.id !== ruleInstanceId);
      return [...clone];
    });
  };

  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    setSavingPdf("pdf");
  };

  const handleDownloadImage = () => {
    setSavingPdf("image");
  };

  useEffect(() => {
    async function handleImage() {
      const element = printRef.current;
      const canvas = await html2canvas(element);

      const data = canvas.toDataURL("image/jpg");
      const link = document.createElement("a");

      if (typeof link.download === "string") {
        link.href = data;
        link.download = `${
          "All_Mapped_Policies" + new Date().toJSON().slice(0, 10)
        }.jpg`;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        window.open(data);
      }
      setSavingPdf(false);
    }

    async function handlePdf() {
      const element = printRef.current;
      window.scrollTo(0, 0);

      const canvas = await html2canvas(element, {
        windowWidth: document.documentElement.offsetWidth,
        windowHeight: document.documentElement.offsetHeight,
      });

      var contentWidth = canvas.width;
      var contentHeight = canvas.height;

      //The height of the canvas which one pdf page can show;
      var pageHeight = (contentWidth / 592.28) * 841.89;
      //the height of canvas that haven't render to pdf
      var leftHeight = contentHeight;
      //addImage y-axial offset
      var position = 0;
      //a4 format [595.28,841.89]
      var imgWidth = 595.28;
      var imgHeight = (592.28 / contentWidth) * contentHeight;

      var pageData = canvas.toDataURL("image/jpeg", 1.0);

      var pdf = new jsPDF("", "pt", "a4");

      if (leftHeight < pageHeight) {
        pdf.addImage(pageData, "JPEG", 0, 0, imgWidth, imgHeight);
      } else {
        while (leftHeight > 0) {
          pdf.addImage(pageData, "JPEG", 0, position, imgWidth, imgHeight);
          leftHeight -= pageHeight;
          position -= 841.89;
          //avoid blank page
          if (leftHeight > 0) {
            pdf.addPage();
          }
        }
      }

      pdf.save(
        "All_Mapped_Policies" + new Date().toJSON().slice(0, 10) + ".pdf"
      );
      setSavingPdf(false);
    }

    if (savingPdf === "image") {
      handleImage();
    } else if (savingPdf === "pdf") {
      handlePdf();
    }
  }, [savingPdf]);

  useEffect(() => {
    if (standards) {
      // filter the sources policy instances down by non-disabled and create a new object to pass to ui
      let allInstances = [];
      standards?.availableBusinessRuleStandards?.edges.forEach((standard) => {
        let standardInstances = [];
        standard?.node?.instances.forEach((instance) => {
          if (
            instance?.enabledState !== "DISABLED" &&
            instance?.dataSource?.enabled
          )
            standardInstances.push({
              ...instance,
              standard: {
                tagInstances: standard?.node?.tagInstances,
                name: standard?.node?.name,
                id: standard?.node?.id,
                latestVersion: standard?.node?.latestVersion,
              },
            });
        });
        allInstances.push(...standardInstances);
      });
      setInstances(allInstances);
    }
  }, [standards]);

  //define initial rule state
  const [instances, setInstances] = useState([]);
  const [showFailureDetails, setShowFailureDetails] = useState(null);
  const [ruleToDelete, setRuleToDelete] = useState(null);

  //remove rules state
  useEffect(() => {
    if (ruleToDelete) {
      setShowConfirmRemove(true);
    } else {
      setShowConfirmRemove(false);
    }
  }, [ruleToDelete]);

  const [
    { errors: approvalStateErrors, data: approvalStateUpdate },
    doUpdateApprovalState,
  ] = useApi();

  const updateApprovalState = React.useCallback(
    (updateApprovalStateEvent, instanceId) => {
      const updateInstanceApprovalState = {
        instanceId: instanceId,
        approvalState: updateApprovalStateEvent.value,
      };

      const variables = {
        ...updateInstanceApprovalState,
      };

      doUpdateApprovalState({ query: setRuleInstanceApprovalState, variables });
    },
    [doUpdateApprovalState]
  );

  const updateApprovalInstance = React.useCallback(
    (updateApprovalStateEvent, instanceId) => {
      setInstances((prev) => {
        const clone = [...prev];
        const currentIndexPack = clone.map((e) => e.id).indexOf(instanceId);
        clone[currentIndexPack].approvalState = updateApprovalStateEvent.value;

        return clone;
      });
      updateApprovalState(updateApprovalStateEvent, instanceId);
    },
    [updateApprovalState]
  );

  useEffect(() => {
    if (approvalStateUpdate?.setRuleInstanceApprovalState) {
      toast.success("Policy Approval State Updated", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [approvalStateUpdate]);

  if (availableStandardsErrors)
    return <ErrorMessages errors={availableStandardsErrors} />;

  const columnsData = [
    {
      Header: "Policy Instance Name",
      id: "name",
      accessor: (d) => d?.title,
      Cell: ({ row: { original } }) => {
        return (
          <StyledLink
            to={ruleStandardById(original?.standard?.id)}
            data-testid="policyLink"
          >
            {original?.title}
          </StyledLink>
        );
      },
    },
    {
      Header: "Policy Name",
      id: "ruleInstanceName",
      accessor: (d) => d?.standard?.name,
      Cell: ({ row: { original } }) => {
        const isLatest =
          original?.latestVersion?.standardVersionId ===
          original?.standard?.latestVersion?.id;

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {!isLatest && (
              <>
                <WarningIcon data-tip="OutOfDate" data-for="OutOfDate">
                  <MdWarning />
                </WarningIcon>
                <ReactTooltip id="OutOfDate" type="error">
                  <span>Policy Out of Date</span>
                </ReactTooltip>
              </>
            )}

            {original?.standard?.name}
          </div>
        );
      },
    },
    {
      Header: "Source Name",
      id: "sourceName",
      accessor: (d) => d?.dataSource?.name,
      Cell: ({ row: { original } }) => {
        const sourceId = original?.dataSource?.id;
        const refreshSummaryId =
          original?.dataSource?.latestReport?.refreshSummaryId;

        const failedRuleInstanceId = original?.id;
        const failedRuleInstanceVersionId = original?.latestVersion?.id;
        const failedRuleStandardVersionId =
          original?.latestVersion?.standardVersionId;

        const published = original?.enabledState === "PUBLISHED";

        return published &&
          refreshSummaryId &&
          failedRuleInstanceId &&
          failedRuleInstanceVersionId &&
          failedRuleStandardVersionId ? (
          <StyledLink
            data-testid="link-datasource"
            to={dataSourceFailureInstanceDetails(
              sourceId,
              refreshSummaryId,
              failedRuleInstanceId,
              failedRuleInstanceVersionId,
              failedRuleStandardVersionId
            )}
          >
            {original?.dataSource?.name}
          </StyledLink>
        ) : sourceId && failedRuleInstanceId && failedRuleInstanceVersionId ? (
          <StyledLink
            data-testid="link-datasource"
            to={`/sources/${sourceId}/manage/policies/${failedRuleInstanceId}/${failedRuleInstanceVersionId}/update`}
          >
            {original?.dataSource?.name}
          </StyledLink>
        ) : (
          <div> {original?.dataSource?.name}</div>
        );
      },
    },
    {
      Header: "Mapping Status",
      id: "enabledState",
      accessor: (d) => d?.enabledState,
    },
    {
      Header: "Approval State",
      id: "approvalState",
      accessor: (d) => d?.approvalState,
      Cell: ({ row: { original } }) => {
        if (canUseBCA(user?.email)) {
          return (
            <div>
              <StyledSelect
                className={`react-select-container`}
                classNamePrefix={`react-select`}
                menuPortalTarget={document.body}
                name={`${original?.id}-approvalState`}
                id={`${original?.id}-approvalState`}
                inputId={`${original?.id}-approvalState-input`}
                instanceId={`${original?.id}-approvalState-instance`}
                options={approvalStates}
                onChange={(e) => updateApprovalInstance(e, original?.id)}
                placeholder={original?.node?.approvalState}
                value={approvalStates.find(
                  (approvalState) =>
                    approvalState.value === original?.approvalState
                )}
                minWidth={200}
              />
            </div>
          );
        } else {
          return (
            <div>
              {
                approvalStates.find(
                  (apprv) => apprv.value === original?.approvalState
                )?.label
              }
            </div>
          );
        }
      },
    },
    {
      Header: "Failed",
      id: "failed",
      accessor: (d) =>
        d?.dataSource?.latestReport?.ruleFailures?.failures.find(
          (failure) => d?.id === failure.failedRuleInstanceId
        )?.failureCount,
    },
    {
      Header: "Passed",
      id: "passed",
      accessor: (d) =>
        d?.dataSource?.latestReport?.ruleFailures?.failures.find(
          (failure) => d?.id === failure.failedRuleInstanceId
        )?.successCount,
    },
    {
      Header: "Date",
      id: "data",
      accessor: (d) => d?.dataSource?.latestReport?.timestamp,
      Cell: ({ row: { original } }) => {
        const date = original?.dataSource?.latestReport?.timestamp;
        if (date) {
          return format(new Date(date), "MM-dd-yyyy HH:mm:ss");
        } else {
          return "";
        }
      },
    },
    {
      Header: "Tags",
      id: "tags",
      accessor: (d) => {
        let output = [];
        d?.standard?.tagInstances?.forEach((instance) => {
          output.push(instance?.tag?.name);
        });
        return output.join(", ");
      },
      Cell: ({ row: { original } }) => {
        return (
          <div>
            {original?.standard?.tagInstances?.map((ti, i) => {
              const isLast = original?.standard?.tagInstances?.length - 1 <= i;
              return (
                <div
                  // onClick={() => setManuallySelected([ti])}
                  style={{
                    display: "inline-block",
                    background: "#e6e6e6",
                    padding: "0.2rem",
                    paddingLeft: ".4rem",
                    paddingRight: ".4rem",
                    fontSize: ".8rem",
                    marginRight: isLast ? "" : ".5rem",
                    marginBottom: isLast ? "" : ".5rem",
                    // cursor: "pointer",
                  }}
                >
                  {ti?.tag?.name}
                </div>
              );
            })}
          </div>
        );
      },
    },
    ...(!savingPdf
      ? [
          {
            Header: "",
            id: "actions",
            sortable: false,
            Cell: ({ row: { original } }) => {
              const dataSourceId = original?.dataSource?.id;
              const ruleVersionId = original?.latestVersion?.id;
              const ruleInstanceId = original?.id;
              const refreshSummaryId =
                original?.dataSource?.latestReport?.refreshSummaryId;

              const failedRuleInstanceId = original?.id;
              const failedRuleInstanceVersionId = original?.latestVersion?.id;
              const failedRuleStandardVersionId =
                original?.latestVersion?.standardVersionId;
              const failedRuleStandardId = original?.standard?.id;
              const published = original?.enabledState === "PUBLISHED";

              return (
                <>
                  {published && refreshSummaryId && (
                    <TableButton
                      list
                      onClick={() =>
                        setShowFailureDetails({
                          dataSourceId,
                          refreshSummaryId,
                          failedRuleInstanceId,
                          failedRuleInstanceVersionId,
                          failedRuleStandardVersionId,
                          failedRuleStandardId,
                        })
                      }
                    >
                      Failure Details
                    </TableButton>
                  )}

                  <TableButton
                    list
                    onClick={() =>
                      setShowConfirm({
                        sourceId: dataSourceId,
                        instanceVersionId: ruleVersionId,
                        ruleInstanceId: ruleInstanceId,
                        title: original?.title,
                        ruleId: failedRuleStandardId,
                      })
                    }
                  >
                    View Mapping
                  </TableButton>
                  <TableButton
                    danger
                    onClick={() =>
                      setRuleToDelete({
                        sourceId: dataSourceId,
                        instanceVersionId: ruleVersionId,
                        ruleInstanceId: ruleInstanceId,
                        title: original?.title,
                      })
                    }
                  >
                    Remove
                  </TableButton>
                </>
              );
            },
          },
        ]
      : []),
  ];

  return (
    <div ref={printRef}>
      <div style={{ display: "flex", margin: "1rem" }}>
        <div style={{ marginLeft: "auto" }}>
          <Button
            style={{}}
            list="true"
            type="button"
            disabled={savingPdf}
            onClick={handleDownloadImage}
          >
            Download JPG
          </Button>
          <Button
            list="true"
            type="button"
            disabled={savingPdf}
            onClick={handleDownloadPdf}
          >
            Download PDF
          </Button>
        </div>
      </div>

      {showFailureDetails ? (
        <FailureDetailsModal
          title={"Failure Details"}
          toggle={() => setShowFailureDetails(null)}
        >
          <FailureDetailsModalContainer
            showFailureDetails={showFailureDetails}
          />
        </FailureDetailsModal>
      ) : null}

      {showConfirmRemove ? (
        <Modal
          title={`Confirm Instance Removal`}
          hide={() => setRuleToDelete(null)}
        >
          <RemoveInstance
            ruleToDelete={ruleToDelete}
            removeInstanceFromState={removeInstanceFromState}
            setRuleToDelete={setRuleToDelete}
            setShowConfirmRemove={setShowConfirmRemove}
          />
        </Modal>
      ) : null}

      {showConfirm ? (
        <Modal title={showConfirm?.title} hide={() => setShowConfirm(null)}>
          <ViewMappingModal
            sourceId={showConfirm?.sourceId}
            instanceVersionId={showConfirm?.instanceVersionId}
            ruleInstanceId={showConfirm?.ruleInstanceId}
            ruleId={showConfirm?.ruleId}
            editEnabled={true}
          />
        </Modal>
      ) : null}
      {approvalStateErrors?.length && (
        <ErrorMessages errors={approvalStateErrors} />
      )}
      <div ref={ref}>
        <SortTable
          noScroll={savingPdf}
          hidePagination={savingPdf}
          loading={availableStandardsLoading}
          data={instances}
          columns={columnsData}
          defaultPageSize={50}
        />
      </div>
    </div>
  );
};

export default DataSourceRulesListWidget;
