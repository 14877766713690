import React, { useEffect } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { useApi } from "../../../api/useApi";
import { format } from "date-fns";
import { sourceWidgetSummary } from "../../../api/dataSourceQueries";
import Loading from "./Loading";
import Error from "./Error";
import DataQualityFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityFailuresCountHover";
import DataQualityOpportunitiesForFailuresCountHover from "../../../components/Widgets/DataSourceWidgets/DataQualityOpportunitiesForFailuresCountHover";
import DataSourceRowsMeasuredHover from "../../../components/Widgets/DataSourceWidgets/DataSourceRowsMeasuredHover";
import DataQualityScore from "../../../components/Widgets/DataSourceWidgets/DataQualityScore";
import { Link } from "react-router-dom";
import { formatTime } from "../../../common/formats";

export function findThresholdScore(grade) {
  let scoreObject;

  const thresholdScores = [
    {
      min: 0,
      max: 39,
      name: "Critical",
      color: "#f87e7e",
    },
    {
      min: 40,
      max: 69,
      name: "Low",
      color: "#E76E3C",
    },
    {
      min: 70,
      max: 94,
      name: "Medium",
      color: "#F6C600",
    },
    {
      min: 95,
      max: 100,
      name: "High",
      color: "#40806A",
    },
  ];

  if (grade <= 0) {
    scoreObject = thresholdScores[0];
  }
  thresholdScores.forEach((s, i) => {
    if (grade >= s.min && grade <= s.max) {
      scoreObject = thresholdScores[i];
    }
  });
  return scoreObject;
}

const ReportSummary = ({ data }) => {
  const [
    { loading: sourceLoading, errors: sourceErrors, data: sourceDataQuery },
    fetchSourceById,
  ] = useApi();

  const sourceData = sourceDataQuery?.dataSource?.latestReport;

  useEffect(() => {
    fetchSourceById({
      query: sourceWidgetSummary,
      variables: { id: data?.dataSource },
    });
  }, [fetchSourceById, data?.dataSource]);

  if (sourceErrors && data) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Error message="There was an error loading the data." />
      </Box>
    );
  }

  if (!sourceDataQuery) {
    return (
      <Box
        sx={{
          marginTop: "2rem",
          padding: 2,
          border: "1px solid #ccc",
          borderRadius: 1,
        }}
      >
        <Loading />
      </Box>
    );
  }

  let qualityScore,
    rowCount,
    failureOpportunities,
    totalFailures,
    date,
    scoreObject;

  if (sourceData) {
    qualityScore = sourceData.qualityScore;
    rowCount = sourceData.rowCount;
    failureOpportunities = sourceData.failureOpportunities;
    totalFailures = sourceData.totalFailures;
    date = qualityScore
      ? format(new Date(qualityScore?.date), "MMM dd/yyyy")
      : null;
    const scoreValue = qualityScore
      ? checkNumberValue(qualityScore?.score)
      : null;
    scoreObject = scoreValue ? findThresholdScore(scoreValue) : null;
  }

  function checkNumberValue(value) {
    return Number.isInteger(value) ? value : null;
  }

  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        sx={{ zIndex: 2, position: "relative", top: "-10px" }}
      >
        {data?.widgetName}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        <span role="img" aria-label="clock">
          🕒
        </span>{" "}
        {sourceDataQuery?.dataSource?.latestReport?.timestamp &&
          formatTime(
            new Date(sourceDataQuery?.dataSource?.latestReport?.timestamp)
          )}
      </Typography>
      <Typography
        variant="body2"
        color="primary"
        component={Link}
        to={`/sources/${sourceDataQuery?.dataSource?.id}`}
      >
        🗄️{sourceDataQuery?.dataSource?.name}
      </Typography>

      {!sourceData ? (
        <Box
          sx={{
            marginTop: "2rem",
            padding: 2,
            border: "1px solid #ccc",
            borderRadius: 1,
          }}
        >
          No Report Found
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "1rem",
              padding: "1rem",
              backgroundColor: "#fff",
              borderRadius: 2,
              boxShadow: 1,
              textDecoration: "none",
              color: "inherit",
            }}
            component={Link}
            to={`/sources/${sourceDataQuery?.dataSource?.id}`}
            title={`View ${sourceDataQuery?.dataSource?.name} Latest Report`}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="h5" component="div">
                QUALITY SCORE
              </Typography>

              <Typography
                variant="h5"
                component="div"
                sx={{ color: scoreObject?.color ?? "red" }}
              >
                {scoreObject?.name}
              </Typography>

              <Typography
                variant="subtitle1"
                component="div"
                sx={{ marginTop: "auto" }}
              >
                {date}
              </Typography>
            </Box>

            <div
              style={{
                position: "relative",
                height: "120px",
                width: "120px",
                marginLeft: "auto",
                marginRight: "2rem",
              }}
            >
              <DataQualityScore loading={sourceLoading} data={qualityScore} />
            </div>
          </Box>

          <Grid container spacing={2} sx={{ marginTop: 2 }}>
            <Grid item xs={12} sm={4}>
              <Box
                component={Link}
                to={`/sources/${sourceDataQuery?.dataSource?.id}/failures`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Paper
                  sx={{
                    padding: 2,
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    boxShadow: 1,
                    color: "inherit",
                  }}
                >
                  <DataSourceRowsMeasuredHover
                    loading={sourceLoading}
                    data={rowCount}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                component={Link}
                to={`/sources/${sourceDataQuery?.dataSource?.id}/failures`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Paper
                  sx={{
                    padding: 2,
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    boxShadow: 1,
                    color: "inherit",
                  }}
                >
                  <DataQualityOpportunitiesForFailuresCountHover
                    data={failureOpportunities}
                    loading={sourceLoading}
                  />
                </Paper>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box
                component={Link}
                to={`/sources/${sourceDataQuery?.dataSource?.id}/failures`}
                sx={{ textDecoration: "none", color: "inherit" }}
              >
                <Paper
                  sx={{
                    padding: 2,
                    backgroundColor: "#fff",
                    borderRadius: 2,
                    boxShadow: 1,
                    color: "inherit",
                  }}
                >
                  <DataQualityFailuresCountHover
                    data={totalFailures}
                    loading={sourceLoading}
                  />
                </Paper>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default ReportSummary;
