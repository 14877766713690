import React from "react";
import {
  StyledSelect,
  FormControl,
  StyledInput,
} from "../../../../Form/FormControls";
import {
  DataSourceContainer,
  DataSourceWrapper,
  DataSourceHeading,
  FieldLabel,
  ThresholdContainer,
  ThresholdButton,
} from "../CreateFormStyles";
import { MdEdit } from "react-icons/md";
import { SmallText } from "../CreateFormStyles";

const DataSourceAction = ({
  dispatch,
  segment,
  stateSources,
  setInsertSegment,
  isInserting,
  insertSegment,
  usedDataSources,
}) => {
  const createDisabledOptions = (options, usedEntities, idKey = "value") => {
    return options.map((option) => ({
      ...option,
      isDisabled: usedEntities.includes(option[idKey]),
    }));
  };

  // Create disabled options for Data Sources
  const dataSourceOptionsWithDisabled = createDisabledOptions(
    stateSources,
    usedDataSources
  );

  const selectedDataSource = stateSources.find(
    (ss) => ss.value === segment?.id
  );

  const setMaxThreshold = (threshold) => {
    let setThreshold = threshold;
    if (threshold > 100) {
      setThreshold = 100;
    } else if (threshold < 0) {
      setThreshold = 0;
    }
    setInsertSegment((prev) => {
      return { ...prev, dataQualityThreshold: Number(setThreshold) };
    });
  };

  return (
    <>
      <DataSourceContainer>
        <DataSourceWrapper>
          <DataSourceHeading>
            {isInserting ? (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <FormControl>
                  <StyledInput
                    type="number"
                    name="dqthreshold"
                    label="Data Quality Threshold"
                    required={true}
                    max={"100"}
                    min={"0"}
                    value={insertSegment?.currentThreshold}
                    placeholder={`Set Data Quality Threshold`}
                    onChange={(e) => setMaxThreshold(e.target.value)}
                  />
                </FormControl>
                <div style={{}}>
                  {" "}
                  <SmallText>
                    Threshold updates will take place across every instance of
                    this Data Source once Workflow is saved.
                  </SmallText>
                </div>
                <FieldLabel>Data Source</FieldLabel>
              </div>
            ) : (
              segment?.id && (
                <>
                  <FieldLabel>Data Source</FieldLabel>
                  <ThresholdButton
                    onClick={() =>
                      dispatch({
                        type: "TOGGLE_CHANGE_DATASOURCE_THRESHOLD",
                        payload: {
                          dataSourceId: segment?.id,
                          segment: segment,
                        },
                      })
                    }
                  >
                    <ThresholdContainer>
                      Min. DQ Score: {segment?.dataQualityThreshold} <MdEdit />
                    </ThresholdContainer>
                  </ThresholdButton>
                </>
              )
            )}
          </DataSourceHeading>

          <StyledSelect
            className={`react-select-container`}
            classNamePrefix={`react-select`}
            name={`datasources`}
            id={`datasources`}
            inputId={`datasourcesSelect-input`}
            instanceId={`datasourcesSelect-instance`}
            label="Data Source"
            options={dataSourceOptionsWithDisabled}
            placeholder={`Select Data Source`}
            menuPortalTarget={document.body}
            value={selectedDataSource ?? null}
            menuPlacement="auto"
            onChange={(e) =>
              isInserting
                ? setInsertSegment((prev) => {
                    return { ...prev, id: e?.value, name: e?.label };
                  })
                : dispatch({
                    type: "SELECT_DATA_SOURCE",
                    payload: {
                      pipeline: segment,
                      dataSource: e,
                      sourceIndex: 0,
                    },
                  })
            }
          />
        </DataSourceWrapper>
      </DataSourceContainer>
    </>
  );
};

export default DataSourceAction;
