import React from "react";
import { useApi } from "../../api/useApi";
import { allBatchNamesByWorkflowId } from "../../api/workflowQueries";
import PagedTable from "../../components/Table/PagedTable";
import ErrorMessages from "../../components/Notifications/ErrorMessages";
import { formatTime } from "../../common/formats";

const resultStateEnums = [
  { value: "NONE", label: "Waiting on Dependencies" },
  { value: "PIPELINE_QUEUED", label: "Queued" },
  { value: "PIPELINE_QUEUED_AND_ALERTED", label: "Queued And Alerted" },
  { value: "PIPELINE_STARTED", label: "Started" },
  { value: "PIPELINE_SUCCEEDED", label: "Success" },
  { value: "CONNECTION_FAILURE", label: "Connection Failure" },
  { value: "CANCELLED", label: "Cancelled" },
  { value: "PIPELINE_FAILED", label: "Failed" },
  { value: "GENERIC_FAILURE", label: "Failure" },
  { value: "COMPLETED", label: "Completed" },
];

const WorkflowTable = ({ workflowId }) => {
  //Init Data Fetch
  const [{ errors, loading, data: apiData }, doFetch] = useApi();

  const batches = apiData?.allBatchNamesByWorkflowId?.edges ?? [];
  const totalCount = apiData?.allBatchNamesByWorkflowId?.totalCount;
  const pageInfo = apiData?.allBatchNamesByWorkflowId?.pageInfo;

  //Fetch for Table Paged
  const fetchData = React.useCallback(
    ({ pageSize, cursor }) => {
      doFetch({
        query: allBatchNamesByWorkflowId,
        variables: {
          first: pageSize,
          after: cursor,
          workflowId: Number(workflowId),
        },
      });
    },
    [doFetch, workflowId]
  );

  const columnsData = [
    {
      Header: "Batch Name",
      id: "batchname",
      accessor: (d) => d?.node?.batchName ? d?.node?.batchName : "None Assigned Yet",
    },
    // {
    //   Header: "Batch Name",
    //   id: "batchname",
    //   accessor: (d) => d?.node?.batchName,
    //   Cell: ({ row: { original } }) => {
    //     return (
    //       <>
    //         <StyledLink
    //           to={paths.workflowByIdAndBatch(
    //             original?.node?.workflowId,
    //             original?.node?.batchName
    //           )}
    //           data-testid="workflowByIdAndBatchLink"
    //         >
    //           {original?.node?.batchName}
    //         </StyledLink>
    //       </>
    //     );
    //   },
    // },
    {
      Header: "Start Time",
      id: "createdOn",
      accessor: (d) => d?.node?.createdOn,
      Cell: ({ row: { original } }) => {
        return formatTime(new Date(original?.node?.createdOn));
      },
    },
    {
      Header: "Status",
      id: "resultstate",
      accessor: (d) => d?.node?.resultState,
      Cell: ({ row: { original } }) => {
        if (original?.node?.resultState === "NONE" && new Date() - new Date(original?.node?.createdOn) > 8.64e7) {
          return "Incomplete";
        } else {
          return (
            resultStateEnums.find(
              (resEnum) => resEnum.value === original?.node?.resultState
            )?.label ?? "N/A"
          );
        }
      },
    },
  ];

  // createdOn is when the batch started.
  // successful

  return (
    <>
      {" "}
      <PagedTable
        fetchData={fetchData}
        loading={loading}
        pageInfo={pageInfo}
        totalCount={totalCount}
        data={batches}
        columns={columnsData}
      />
      {errors ? <ErrorMessages errors={errors} /> : null}
    </>
  );
};

export default WorkflowTable;
