import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import styled from "styled-components/macro";
import { MdVpnKey, MdDeviceHub, MdLibraryBooks, MdFeed } from "react-icons/md";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { useApi } from "../../api/useApi";
import SplashLoader from "../../components/Loaders/SplashLoader";
import MyIssues from "../IssuesPage/MyIssues";
import { PinContext } from "../../contexts/PinContext";
import { FaDatabase } from "react-icons/fa";
import StyledLink from "../../components/StyledLink";
import * as paths from "../../common/paths";
import PinButton from "../../components/Pin/PinButton";
import MiniScore from "./MiniScore";
import { availableWorkGroups } from "../../api/workgroupQueries";
import AddGroups from "../AdminPage/AddGroups";
import Modal from "../../components/Modal";
import Workspace from "../Workspaces/Workspace";
import { favoriteWorkspace } from "../../api/workspaceQueries";
import ErrorMessages from "../../components/Notifications/ErrorMessages";

const WelcomeMessagesContainer = styled(Link)`
  text-decoration: none;
  padding: 1em;
  border: 2px solid ${(props) => props.theme.onSurface};
  border-radius: 4px;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  color: ${(props) => props.theme.onSurface};
  svg {
    margin-right: 1em;
    font-size: 2em;
  }
  &:hover {
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    svg {
      margin-right: 1em;
      font-size: 1em;
    }
  }
  @media (max-width: 593px) {
    font-size: 17px;
    svg {
      margin-right: 1em;
      font-size: 1em;
    }
  }
`;

const customWelcomeAdminGraphQuery = /* GraphQL */ `
  query availableConnections($where: ConnectionFilterInput) {
    availableConnections(where: $where) {
      totalCount
    }
  }
`;

const dataSourceQuery = /* GraphQL */ `
  query availableDataSources($where: DataSourceFilterInput) {
    availableDataSources(where: $where) {
      totalCount
    }
  }
`;

const allServicerTransferFeeds = /* GraphQL */ `
  query allServicerTransferFeeds($where: ServicerTransferFeedModelFilterInput) {
    allServicerTransferFeeds(where: $where) {
      totalCount
    }
  }
`;

export const pinnedSourcesQuery = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [DataSourceSortInput!]
    $where: DataSourceFilterInput
  ) {
    availableDataSources(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          name
          latestReport {
            timestamp
            refreshSummaryId
            refreshSummary {
              createdOn
              resultState
            }
            qualityScore {
              score
            }
          }
        }
      }
    }
  }
`;

export const pinnedPoliciesQuery = /* GraphQL */ `
  query (
    $first: Int
    $after: String
    $order: [BusinessRuleStandardSortInput!]
    $where: BusinessRuleStandardFilterInput
  ) {
    availableBusinessRuleStandards(
      first: $first
      after: $after
      order: $order
      where: $where
    ) {
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`;

const standardsQuery = /* GraphQL */ `
  query availableBusinessRuleStandards(
    $where: BusinessRuleStandardFilterInput
  ) {
    availableBusinessRuleStandards(where: $where) {
      totalCount
    }
  }
`;

const ProfileDashboard = () => {
  const { user } = useContext(AuthContext);

  function Body() {
    const [{ data: groupData }] = useApi(availableWorkGroups, {
      where: { enabled: { eq: true } },
    });

    const [
      {
        errors: workspaceErrors,
        loading: workspacesLoading,
        data: workspacesData,
      },
    ] = useApi(favoriteWorkspace);

    const [showAddGroup, setShowAddGroup] = useState();

    const hasFavWorkspace = workspacesData?.favoriteWorkspace
      ? {
          ...workspacesData?.favoriteWorkspace,
          configuration: JSON.parse(
            workspacesData?.favoriteWorkspace?.configuration
          ),
        }
          : null;
      console.log(hasFavWorkspace);

    useEffect(() => {
      if (groupData && !groupData?.availableWorkGroups?.edges?.length) {
        //IF ADMIN ROLE SHOW THE PROMPT
        if (user && user?.role === 3) {
          setShowAddGroup(true);
        }
      }
    }, [groupData]);

    //Prime GraphAPI For Permissions Updates
    const [{ loading, data }] = useApi(
      customWelcomeAdminGraphQuery,
      { where: { enabled: { eq: true } } },
      null,
      "availableConnections"
    );

    const [{ loading: loadingDataSources, data: availableDataSources }] =
      useApi(
        dataSourceQuery,
        { where: { enabled: { eq: true } } },
        null,
        "availableDataSources"
      );

    const { pinnedSources, pinnedPolicies } = useContext(PinContext);

    const [{ data: pinnedSourcesData }, doFetchSources] = useApi();

    useEffect(() => {
      if (pinnedSources?.length) {
        doFetchSources({
          query: pinnedSourcesQuery,
          variables: {
            order: {
              name: "ASC",
            },
            where: {
              OR: [
                ...pinnedSources.map((ps) => {
                  return { id: ps };
                }),
              ],
            },
          },
        });
      }
    }, [pinnedSources, doFetchSources]);

    const [{ data: pinnedPoliciesData }, doFetchPolicies] = useApi();

    useEffect(() => {
      if (pinnedPolicies?.length) {
        doFetchPolicies({
          query: pinnedPoliciesQuery,
          variables: {
            order: {
              name: "ASC",
            },
            where: {
              OR: [
                ...pinnedPolicies.map((ps) => {
                  return { id: ps };
                }),
              ],
            },
          },
        });
      }
    }, [pinnedPolicies, doFetchPolicies]);

    const [
      { loading: loadingStandards, data: availableBusinessRuleStandards },
    ] = useApi(
      standardsQuery,
      { where: { enabled: { eq: true } } },
      null,
      "availableBusinessRuleStandards"
    );

    const [
      {
        loading: loadingServicerTransferFeeds,
        data: allServicerTransferFeedsData,
      },
    ] = useApi(
      allServicerTransferFeeds,
      { where: { enabled: { eq: true } } },
      null,
      "allServicerTransferFeeds"
    );

    const connections = data?.availableConnections?.totalCount || null;

    const dataSources =
      availableDataSources?.availableDataSources?.totalCount || null;

    const standards =
      availableBusinessRuleStandards?.availableBusinessRuleStandards
        ?.totalCount || null;

    const feeds =
      allServicerTransferFeedsData?.allServicerTransferFeeds?.totalCount ||
      null;

    return (
      <>
        {showAddGroup ? (
          <Modal
            title={`Add Your First Group to the Platform`}
            hide={() => setShowAddGroup(null)}
          >
            <p>
              Search for your first Active Directory Permission Group you would
              like to enable in the platform.
            </p>
            <AddGroups setShowAddGroup={setShowAddGroup} welcome={true} />
          </Modal>
        ) : null}

        {/* Connections Messages */}
        {!data ||
        loading ||
        loadingStandards ||
        loadingDataSources ||
        workspacesLoading ||
        loadingServicerTransferFeeds ? (
          <SplashLoader text="Loading Platform Data" />
        ) : (
          <>
            {hasFavWorkspace && (
              <Workspace incomingWorkspace={hasFavWorkspace} />
            )}

            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, marginRight: "2rem" }}>
                <h3>Overview</h3>
                {user && user.role > 1 ? (
                  <WelcomeMessagesContainer
                    to={connections ? "/connections" : "connections/create"}
                  >
                    <div>
                      <MdVpnKey />
                    </div>
                    {connections ? (
                      <div>
                        {connections}{" "}
                        <strong>
                          Connection
                          {connections > 1 ? "s " : " "}
                        </strong>
                        {connections > 1 ? "are" : "is"} available to the
                        Platform.
                      </div>
                    ) : (
                      <div>
                        <p>
                          Connections must be created on the platform to begin
                          the data remediation process.
                        </p>

                        <p>
                          You currently have no access to connections, please
                          add a connection or contact an admin if you think this
                          is an error.
                        </p>
                      </div>
                    )}
                  </WelcomeMessagesContainer>
                ) : null}

                {/* Data Source Messages */}
                <WelcomeMessagesContainer
                  to={
                    dataSources
                      ? "/sources"
                      : user && user.role > 2
                      ? "/connections/create"
                      : "/sources"
                  }
                >
                  <div>
                    <MdDeviceHub />
                  </div>

                  {dataSources ? (
                    <div>
                      You currently have access to {dataSources}{" "}
                      <strong>
                        Data Source
                        {dataSources > 1 ? "s" : ""}
                      </strong>
                      .
                    </div>
                  ) : (
                    <div>
                      <p>
                        Once there are available connections, you'll need to go
                        to your sources list and edit the individual frequency
                        at which each Data Source is updated.
                      </p>
                      <p>
                        You currently have no data sources, please add a
                        connection with valid sources to automatically gather
                        data sources.
                      </p>
                    </div>
                  )}
                </WelcomeMessagesContainer>

                <WelcomeMessagesContainer
                  to={standards ? "/policies/rules" : "/policies/rules/create"}
                >
                  <div>
                    <MdLibraryBooks />
                  </div>

                  {standards ? (
                    <div>
                      There {standards > 1 ? "are" : "is"} {standards}{" "}
                      <strong>{standards > 1 ? "Policies" : "Policy"}</strong>{" "}
                      created in the Platform.
                    </div>
                  ) : (
                    <div>
                      <p>
                        Business Rule Standard's must be mapped to a Data Source
                        via the "Map" button on the Data Source List to begin
                        running checks.
                      </p>
                      <p>
                        You currently have no Business Rule Standards, please
                        add one and map it to a Data Source to begin.
                      </p>
                    </div>
                  )}
                </WelcomeMessagesContainer>

                {/* Data Source Messages */}
                <WelcomeMessagesContainer to={"/feeds"}>
                  <div>
                    <MdFeed />
                  </div>

                  {feeds ? (
                    <div>
                      You have {feeds}{" "}
                      <strong>
                        Transfer
                        {feeds > 1 ? "s" : ""}
                      </strong>{" "}
                      on the Platform.
                    </div>
                  ) : (
                    <div>
                      <p>You currently have no Transformations.</p>
                    </div>
                  )}
                </WelcomeMessagesContainer>
              </div>
              <div style={{ flex: 1 }}>
                <div style={{ marginBottom: "2rem" }}>
                  <h3>Quick Access</h3>
                  <h4
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "2rem",
                    }}
                  >
                    <FaDatabase style={{ marginRight: ".5rem" }} />
                    Sources
                  </h4>
                  {pinnedSources?.length ? (
                    pinnedSourcesData?.availableDataSources?.edges.map(
                      (psd, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: ".6rem",
                            }}
                            key={`sources${i}`}
                          >
                            <MiniScore
                              sourceId={psd?.node?.id}
                              data={psd?.node?.latestReport?.qualityScore}
                            />

                            <StyledLink
                              to={paths?.dataSource(psd?.node?.id)}
                              data-testid="sourceLink"
                            >
                              {psd?.node?.name}
                            </StyledLink>

                            <div style={{ marginLeft: "auto" }}>
                              <PinButton type="sources" item={psd?.node?.id} />
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div style={{ fontSize: ".8rem" }}>No Pinned Sources</div>
                  )}

                  <h4
                    style={{
                      marginTop: "2rem",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <MdLibraryBooks style={{ marginRight: ".5rem" }} />
                    Policies
                  </h4>
                  {pinnedPolicies?.length ? (
                    pinnedPoliciesData?.availableBusinessRuleStandards?.edges.map(
                      (psd, i) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginBottom: ".6rem",
                            }}
                            key={`policies${i}`}
                          >
                            <StyledLink
                              to={paths?.ruleStandardById(psd?.node?.id)}
                              data-testid="policyLink"
                            >
                              {psd?.node?.name}
                            </StyledLink>

                            <div style={{ marginLeft: "auto" }}>
                              <PinButton type="policies" item={psd?.node?.id} />
                            </div>
                          </div>
                        );
                      }
                    )
                  ) : (
                    <div style={{ fontSize: ".8rem" }}>No Pinned Policies</div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <MyIssues />
            </div>
            {workspaceErrors && <ErrorMessages errors={workspaceErrors} />}
          </>
        )}
      </>
    );
  }

  return (
    <Card
      title={`Welcome, ${user?.name}`}
      titleDescription={"Platform Overview"}
      body={Body}
    />
  );
};

export default ProfileDashboard;
